<template>
  <div class="table-content">
    <div class="table-header">
      <div class="row status-list-wrap" v-if="statusList.length > 0">
        <a-tabs default-active-key="" @change="setSearchStatus">
          <a-tab-pane key="">
            <template slot="tab">
              <span>{{ $t('全部') }}</span>
            </template>
          </a-tab-pane>
          <a-tab-pane v-for="item in statusList" :key="item.code">
            <template slot="tab">
              <span>{{ $t(item.name) }}</span>
            </template>
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="action-list flex-ct" v-show="dataSource.length">
        <slot name="header"></slot>
        <TableSetting v-if="showSetting" :columns="columns" @change="changeColumns" />
      </div>
    </div>
    <div class="table-body">
      <a-table
        :row-selection="
          showSelect
            ? {
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
                getCheckboxProps: (record) => ({
                  props: {
                    disabled: record.type === 'summary', // 根据条件禁用选择框
                  },
                }),
              }
            : null
        "
        :size="size"
        :loading="loading"
        :scroll="scroll"
        :columns="commonColumns"
        :data-source="dataSource"
        :rowKey="rowKey ? rowKey : (record, index) => index"
        :pagination="false"
        :customRow="customRow"
        v-bind="tableProps"
        :row-class-name="rowClassName"
      >
        <template slot="no" slot-scope="text, record, index">
          <span v-if="record.type !== 'summary'">{{ index + 1 }}</span>
          <span v-else>{{ $t('合计') }}</span>
        </template>
        <template
          v-for="column in columns"
          :slot="column.scopedSlots?.customRender"
          slot-scope="text, record, index"
        >
          <slot
            v-if="record.type !== 'summary' || (record.type === 'summary' && text !== undefined)"
            :name="column.scopedSlots?.customRender"
            :text="text"
            :record="record"
            :index="index"
          />
        </template>
      </a-table>
    </div>
    <div class="table-footer" v-if="showPagination">
      <pagination
        :pageNo="pageNo"
        :length="pageSize"
        :total-count="total"
        @paginate="paginate"
      ></pagination>
    </div>
  </div>
</template>
<script>
import deepClone from '@/common/deepClone'
import pagination from '@/component/pagination.vue'
import TableSetting from '@/component/tableSetting.vue'
export default {
  components: {
    pagination,
    TableSetting,
  },
  props: {
    statusList: { type: Array, default: () => [] },
    dataSource: {
      type: Array,
      default: () => [],
    },
    showSelect: {
      //是否可以选择
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'small',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
    },
    tableProps: {
      type: Object,
      default: () => ({}),
    },
    scroll: {
      type: Object,
      default: () => ({}),
    },
    total: {
      type: Number,
    },
    selectedRowKeys: {
      type: Array,
      default: () => [],
    },
    showSetting: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 20,
      selecteds: [],
      selectedRows: [],
      status: undefined,
    }
  },
  computed: {
    commonColumns() {
      return [
        {
          title: '#',
          align: 'center',
          key: 'NO',
          width: 60,
          fixed: 'left',
          scopedSlots: {
            customRender: 'no',
          },
        },
        ...this.columns.filter((item) => !item.hide),
      ]
    },
  },
  watch: {
    selecteds(newValue) {
      this.$emit('update:selectedRowKeys', newValue)
    },
  },
  methods: {
    rowClassName({ key }) {
      return this.selectedRowKeys.includes(key) ? 'selected-row' : ''
    },
    queryList(page_no) {
      if (page_no) {
        this.pageNo = page_no
      }
      this.selecteds = []
      this.selectedRows = []
      this.$emit('search', { page_no: this.pageNo, page_size: this.pageSize, status: this.status })
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.queryList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selecteds = selectedRowKeys
      this.selectedRows = selectedRows
      this.$emit('select', selectedRowKeys, selectedRows)
    },
    setSearchStatus(status) {
      this.status = status
      this.pageNo = 1
      this.onSelectChange([], [])
      this.$nextTick(() => {
        this.queryList()
      })
    },
    customRow(record, index) {
      return {
        class: record.type === 'summary' ? 'summary-row' : '',
        on: {
          click: (event) => this.handleRowClick(event, record, index),
        },
      }
    },
    changeColumns(list) {
      this.$emit('update:columns', list)
    },
    handleRowClick(event, record, index) {
      // 检查点击的目标元素是否是按钮或输入框
      if (event.target.tagName === 'BUTTON' || event.target.tagName === 'INPUT') {
        // 如果是按钮或输入框，阻止事件的进一步传播
        return
      }
      if (!this.showSelect) return
      const key = this.rowKey ? record[this.rowKey] : index
      const selectedIndex = this.selectedRowKeys.indexOf(key)
      const selectedRowKeys = deepClone(this.selecteds)
      const selectedRows = deepClone(this.selectedRows)
      if (selectedIndex >= 0) {
        // 如果已经选中，则取消选中
        selectedRowKeys.splice(selectedIndex, 1)
        selectedRows.splice(selectedIndex, 1)
      } else {
        // 如果没有选中，则选中
        selectedRowKeys.push(key)
        selectedRows.push(record)
      }
      // 更新选中状态
      this.onSelectChange(selectedRowKeys, selectedRows)
    },
  },
}
</script>

<style scoped lang="scss">
.table-content .table-footer {
  padding: 10px 20px 4px !important;
}
.table-content :deep(.summary-row) {
  background-color: #fef8e4 !important;
  color: #eb743f !important;
}
.table-content :deep(.ant-table-fixed-header .ant-table-scroll .ant-table-header) {
  padding-bottom: 0 !important;
}
</style>
