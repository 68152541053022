<template>
  <a-select
    allowClear
    :mode="mode"
    show-search
    :maxTagCount="4"
    style="width: 100%"
    :placeholder="_selfPlaceholder"
    :disabled="_selfDisabled"
    v-model="selectedValue"
    :filter-option="filterOption"
    @change="onChange"
  >
    <a-select-option v-for="(item, index) in option" :value="item[valueName]" :key="index">
      {{ item[labelName] }}
    </a-select-option>
  </a-select>
</template>

<script>
// 通过父元素传参获取list
export default {
  props: {
    id: {},
    placeholder: {},
    disabled: {},
    code: {},
    name: {},
    mode: {
      type: String,
      default: '',
    },
    list: [],
    valueName: {
      type: String,
      default: 'code',
    },
    labelName: {
      type: String,
      default: 'name',
    },
    isBu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: undefined,
      option: [],
    }
  },
  computed: {
    _selfPlaceholder() {
      return this.placeholder
    },
    _selfDisabled() {
      return this.disabled || false
    },
  },
  watch: {
    list: {
      handler() {
        this.getList()
      },
      immediate: true,
    },
    code() {
      if (this.option.length > 0) {
        this.setDefault()
      } else if (!this.name) {
        this.getList()
      }
    },
    name: {
      handler(val) {
        if (val) {
          this.selectedValue = val
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getList() {
      let list = this.list || []
      this.option = list
      this.setDefault()
    },
    setDefault() {
      if (this.name) return
      this.selectedValue = undefined
      if (this.code != undefined) {
        this.onChange(this.code)
      } else if (this.option.length === 1) {
        this.onChange(this.option[0][this.valueName])
      } else if (this.isBu) {
        this.onChange(this.$store.state.defaultBu?.[this.valueName])
      }
    },
    onChange(value) {
      this.$nextTick(() => {
        if (this.mode !== 'multiple' && value !== undefined) {
          value = String(value)
        }
        if (this.mode === 'multiple' && typeof value === 'string') {
          value = [value]
        }
        this.selectedValue = value
        this.$emit('update:code', value)
        if (this.mode !== 'multiple') {
          let item = this.option.find((elem) => String(elem[this.valueName]) === value) || {}
          this.$emit('update:name', item[this.labelName])
          this.$emit('change', item)
        } else {
          this.$emit('change', value)
        }
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>
