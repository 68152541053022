<template>
  <div class="amount-input">
    <span v-if="showTxt">{{ displayValue }}</span>
    <input
      v-else
      style="width: 100%"
      class="ant-input amount-input-content"
      :class="right ? 'text-right' : ''"
      type="text"
      :placeholder="placeholder"
      :value="displayValue"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
      :disabled="disabled"
      @select="selectAll"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    placeholder: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    //付款货币
    currency: {
      type: String,
      default: 'CNY',
    },
    right: {
      type: Boolean,
      default: false,
    },
    showTxt: {
      type: Boolean,
      default: false,
    },
    showZero: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rawValue: this.value,
      isFocused: false,
    }
  },
  watch: {
    value(newValue) {
      this.rawValue = newValue // 监听 value 的变化
    },
  },
  computed: {
    displayValue() {
      let val = this.$currencySymbol(this.rawValue, this.currency)
      return this.isFocused ? this.getNumber(this.rawValue) : this.showZero && !val ? 0 : val
    },
  },
  methods: {
    getNumber(val) {
      if (val === undefined || val === '' || val === null) {
        return undefined
      }
      val = String(val)
      // 如果值包含小数点并且最后一个字符是小数点，直接返回
      if (val?.includes('.') && val[val?.length - 1] === '.') {
        return val
      }
      // 处理负数情况
      const numberValue = Number(val)
      return isNaN(numberValue) ? val : numberValue
    },
    onFocus() {
      this.isFocused = true
      this.selectAll() // 在获取焦点时全选内容
    },
    onBlur() {
      this.isFocused = false
      this.$emit('update:value', this.rawValue) // 传递输入的值给父元素
      this.$emit('blur', this.rawValue)
    },
    onInput(event) {
      const value = event.target.value?.trim()
      // 允许输入负数、数字和小数点
      if (/^-?\d*\.?\d*$/.test(value)) {
        this.rawValue = value
      }
    },
    selectAll() {
      this.$nextTick(() => {
        const input = this.$el.querySelector('.amount-input-content')
        input.select() // 全选输入框内容
      })
    },
  },
}
</script>

<style scoped>
.amount-input {
  position: relative;
}
.text-right {
  text-align: right;
}
</style>
