export default [
  {
    path: '/',
    name: 'index',
    redirect: '/login',
    component: () => import('./view/index'),
    children: [
      {
        path: '/payableManage/payableList',
        name: 'payableList',
        component: () => import('./view/payableManage/payableList.vue'), //应付订单列表
      },
      {
        path: '/payableManage/payableDetail',
        name: 'payableDetail',
        component: () => import('./view/payableManage/payableDetail.vue'), //应付订单详情
      },
      {
        path: '/payableManage/paymentRequestList',
        name: 'paymentRequestList',
        component: () => import('./view/payableManage/paymentRequestList.vue'), //付款申请单列表
      },
      {
        path: '/payableManage/paymentRequestDetail',
        name: 'paymentRequestDetail',
        component: () => import('./view/payableManage/paymentRequestDetail.vue'), //付款申请单详情
      },
      {
        path: '/payableManage/paymentNoteList',
        name: 'paymentNoteList',
        component: () => import('./view/payableManage/paymentNoteList.vue'), //付款单列表
      },
      {
        path: '/payableManage/paymentNoteDetail',
        name: 'paymentNoteDetail',
        component: () => import('./view/payableManage/paymentNoteDetail.vue'), //付款单详情
      },
      {
        path: '/payableManage/depositPaymentList',
        name: 'depositPaymentList',
        component: () => import('./view/payableManage/depositPaymentList.vue'), //预付单列表
      },
      {
        path: '/payableManage/depositPaymentDetail',
        name: 'depositPaymentDetail',
        component: () => import('./view/payableManage/depositPaymentDetail.vue'), //预付单详情
      },
      {
        path: '/receivableManage/receivableList',
        name: 'receivableList',
        component: () => import('./view/receivable/receivableList.vue'), //应收订单列表
      },
      {
        path: '/receivableManage/receivableDetail',
        name: 'receivableDetail',
        component: () => import('./view/receivable/receivableDetail.vue'), //应收订单详情
      },
      {
        path: '/receivableManage/receiptNoteList',
        name: 'receiptNoteList',
        component: () => import('./view/receivable/receiptNoteList.vue'), //收款单列表
      },
      {
        path: '/receivableManage/receiptNoteDetail',
        name: 'receiptNoteDetail',
        component: () => import('./view/receivable/receiptNoteDetail.vue'), //收款单详情
      },
      {
        path: '/receivableManage/writtenOffOrderList',
        name: 'writtenOffOrderList',
        component: () => import('./view/receivable/writtenOffOrderList.vue'), //核销单列表
      },
      {
        path: '/receivableManage/createWrittenOffOrder',
        name: 'createWrittenOffOrder',
        component: () => import('./view/receivable/createWrittenOffOrder.vue'), //创建核销单
      },
      {
        path: '/invoiceManage/salesInvoiceList',
        name: 'salesInvoiceList',
        component: () => import('./view/invoiceManage/invoiceList.vue'), //销售发票列表
      },
      {
        path: '/invoiceManage/purchaseInvoiceList',
        name: 'purchaseInvoiceList',
        component: () => import('./view/invoiceManage/invoiceList.vue'), //采购发票列表
      },
      {
        path: '/invoiceManage/invoiceDetail',
        name: 'invoiceDetail',
        component: () => import('./view/invoiceManage/invoiceDetail.vue'), //新建发票
      },
      {
        path: '/baseInfo/accountElement',
        name: 'accountElement',
        component: () => import('./view/baseInfo/accountElement.vue'), //会计要素
      },
      {
        path: '/baseInfo/accountList',
        name: 'accountList',
        component: () => import('./view/baseInfo/accountList.vue'), //会计科目
      },
      {
        path: '/baseInfo/accountDetail',
        name: 'accountDetail',
        component: () => import('./view/baseInfo/accountDetail.vue'), //会计科目
      },
      {
        path: '/baseInfo/accountTable',
        name: 'accountTable',
        component: () => import('./view/baseInfo/accountTable.vue'), //科目表
      },
      {
        path: '/baseInfo/supplierList',
        name: 'supplierList',
        component: () => import('./view/baseInfo/supplierList.vue'), //供应商
      },
      {
        path: '/baseInfo/settlementMethod',
        name: 'settlementMethod',
        component: () => import('./view/baseInfo/settlementMethod.vue'), //计算方式
      },
      {
        path: '/baseInfo/assetStorageLocation',
        name: 'assetStorageLocation',
        component: () => import('./view/baseInfo/assetStorageLocation.vue'), //资产存放地点
      },
      {
        path: '/voucherManage/voucherList',
        name: 'voucherList',
        component: () => import('./view/voucherManage/voucherList.vue'), //凭证列表
      },
      {
        path: '/voucherManage/voucherDetail',
        name: 'voucherDetail',
        component: () => import('./view/voucherManage/voucherDetail.vue'), //凭证详情
      },
      {
        path: '/voucherManage/ledgerList',
        name: 'ledgerList',
        component: () => import('./view/voucherManage/ledgerList.vue'), //明细账列表
      },
      {
        path: '/asset/assetCardList',
        name: 'assetCardList',
        component: () => import('./view/asset/assetCardList.vue'), //资产卡片列表
      },
      {
        path: '/asset/assetCardDetail',
        name: 'assetCardDetail',
        component: () => import('./view/asset/assetCardDetail.vue'), //资产卡片详情
      },
      {
        path: '/asset/assetDepreciationList',
        name: 'assetDepreciationList',
        component: () => import('./view/asset/assetDepreciationList.vue'), //资产折旧单列表
      },
      {
        path: '/asset/assetDepreciationDetail',
        name: 'assetDepreciationDetail',
        component: () => import('./view/asset/assetDepreciationDetail.vue'), //资产折旧单详情
      },
      {
        path: '/asset/assetChangeList',
        name: 'assetChangeList',
        component: () => import('./view/asset/assetChangeList.vue'), //资产变更单列表
      },
      {
        path: '/asset/assetChangeDetail',
        name: 'assetChangeDetail',
        component: () => import('./view/asset/assetChangeDetail.vue'), //资产变更单详情
      },
      {
        path: '/asset/assetImpairmentList',
        name: 'assetImpairmentList',
        component: () => import('./view/asset/assetImpairmentList.vue'), //资产减值单列表
      },
      {
        path: '/asset/assetImpairmentDetail',
        name: 'assetImpairmentDetail',
        component: () => import('./view/asset/assetImpairmentDetail.vue'), //资产减值单详情
      },
      {
        path: '/asset/assetCleanupList',
        name: 'assetCleanupList',
        component: () => import('./view/asset/assetCleanupList.vue'), //资产清理单列表
      },
      {
        path: '/asset/assetCleanupDetail',
        name: 'assetCleanupDetail',
        component: () => import('./view/asset/assetCleanupDetail.vue'), //资产清理单详情
      },
      {
        path: '/capitalManage/capitalAccountList',
        name: 'capitalAccountList',
        component: () => import('./view/capitalManage/capitalAccountList.vue'), //资金账户
      },
      {
        path: '/capitalManage/capitalAccountDetail',
        name: 'capitalAccountDetail',
        component: () => import('./view/capitalManage/capitalAccountDetail.vue'), //资金账户
      },
      {
        path: '/capitalManage/capitalJournalList',
        name: 'capitalJournalList',
        component: () => import('./view/capitalManage/capitalJournalList.vue'), //资金流水
      },
      {
        path: '/capitalManage/capitalJournalDetail',
        name: 'capitalJournalDetail',
        component: () => import('./view/capitalManage/capitalJournalDetail.vue'), //资金流水
      },
      {
        path: '/capitalManage/capitalAccountInitList',
        name: 'capitalAccountInitList',
        component: () => import('./view/capitalManage/capitalAccountInitList.vue'), //资金账户初始化
      },
      {
        path: '/capitalManage/capitalAccountInitDetail',
        name: 'capitalAccountInitDetail',
        component: () => import('./view/capitalManage/capitalAccountInitDetail.vue'), //资金账户初始化
      },
      {
        path: '/404',
        name: '404',
        component: () => import('./view/common/404'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./view/login'),
  },
  {
    path: '/403',
    name: '403',
    component: () => import('./view/common/403'),
  },
  // 此处需特别注意至于最底部
  {
    path: '*',
    redirect: '/404',
  },
]
